.App {
  display: flex;
  flex-direction: column;

  min-height: 100vh;

  padding-top: 70px;

  /*text-align: center;*/
}

@media (min-width: 768px) {
  .App {
    padding-top: 100px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: Gotham;
  src: url(./fonts/Gotham/300.woff2) format('woff2');
  font-weight: 300;
}
@font-face {
  font-family: Gotham;
  src: url(./fonts/Gotham/400.woff2) format('woff2');
  font-weight: 400;
}
@font-face {
  font-family: Gotham;
  src: url(./fonts/Gotham/500.woff2) format('woff2');
  font-weight: 500;
}
@font-face {
  font-family: Gotham;
  src: url(./fonts/Gotham/700.woff2) format('woff2');
  font-weight: 700;
}
@font-face {
  font-family: Gotham;
  src: url(./fonts/Gotham/900.woff2) format('woff2');
  font-weight: 900;
}
@font-face {
  font-family: Gilmer;
  src: url(./fonts/Gilmer/400.woff2) format('woff2');
  font-weight: 400;
}
@font-face {
  font-family: Gilmer;
  src: url(./fonts/Gilmer/700.woff2) format('woff2');
  font-weight: 700;
}
